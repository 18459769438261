import { deleteToken, getUserToken, setToken } from '@modules/utils/cookie';
import { getUserInfo } from '@modules/api/user';
import type { UserResponse } from '@modules/types/api/user';
import type { UserBasicInfoType, UserPlanType } from '@modules/types/user';

export const setUserInfo = (
    data: UserResponse,
): { userInfo: UserBasicInfoType & { accessToken: string | null }; userPlanInfo: UserPlanType } => {
    if (data.token) {
        setToken(data.token.token, data.token.refreshToken);
    }

    return {
        userInfo: {
            accessToken: data.token?.token || getUserToken(),
            type: data.type === 'wavedeck' ? 'voli' : 'social',
            email: data.email,
            nickname: data.nickname,
            profileImageURL: data.profile_img,
        },

        userPlanInfo: {
            planName: data.plan,
            usedTime: data.plan_time,
            usedCharacters: data.plan_txt,
            usedCustomVoice: data.request_cnt !== undefined ? data.request_cnt : 0,
            planStartDate: data.useDt.split(' ~ ')[0],
            planEndDate: data.useDt.split(' ~ ')[1],
            planRenewalDate: data.paymentDt,
            willChange: data.toplan ? (data.toplan !== '' ? { planName: data.toplan } : undefined) : undefined,
            mustChooseCustomVoice: data.cv_cnt_over === 'Y',
            usedCoupon: data.couponYN === 'Y',
            isUsedAIDuringPlan: data.usecnv === 'Y' || data.usecv === 'Y',
            hasPaymentRecord: data.paymentYN === 'Y',
            hasPurchasedWithDiscount: data.indiscount === 'Y',
            paymentMethod: data.paymentMethod,
        },

        // receivesUpdates: data.marketing_alarm === 'Y',
    };
};

export const getUser = async () => {
    try {
        const { success, data } = await getUserInfo();
        if (success) {
            return setUserInfo(data);
        }
        throw data;
    } catch (e) {
        deleteToken();
        return false;
    }
};
