import { atom, selectorFamily } from 'recoil';
import { UserPlan } from '@modules/state/user';
import type { Plan } from '@modules/types/plan';

export const RPlanList = atom<Plan[]>({
    key: 'planList',
    default: [],
});

export const PlanItem = selectorFamily({
    key: 'planItem',
    get:
        ({
            id,
        }: {
            id: number | string; // id or name
        }) =>
        ({ get }) => {
            const planList = get(RPlanList);

            if (typeof id === 'number') {
                return planList.filter((item) => item.id === id)[0];
            }

            return planList.filter((item) => item.name === id)[0];
        },
});

export const PlanValue = selectorFamily({
    key: 'planValue',
    get:
        ({
            key,
            id,
            planName,
        }: {
            key: 'id' | 'level' | 'customVoice' | 'availableTime';
            id?: number;
            planName?: string;
        }) =>
        ({ get }) => {
            const planList = get(RPlanList);

            if (planList.length === 0) return 0;

            if (id !== undefined) {
                return planList.filter((item) => item.id === id)[0][key];
            }

            let plan = '';

            if (planName !== undefined) {
                plan = planName;
            } else {
                plan = get(UserPlan).planName;
            }

            if (plan === '') return 0;

            return planList.filter((item) => item.name === plan)[0][key];
        },
});
