import moment from 'moment/moment';

export const formatting = (e: number): string => {
    const totalSeconds = Math.floor(e / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
};
export const timerFormatting = (e: number) => {
    const minute = Math.trunc(e / 1000 / 60);
    const seconds = Math.trunc(e / 1000) - 60 * minute;
    const milliseconds = Math.trunc(e / 10) - 100 * Math.trunc(e / 1000);
    return `${minute}:${seconds > 9 ? seconds : `0${seconds}`}.${milliseconds > 9 ? milliseconds : `0${milliseconds}`}`;
};

export const timeFormatting = (locale: 'ko' | 'en', time: number) => {
    if (time <= 0) {
        if (locale === 'ko') return '0분 0초';
        return '0min 0sec';
    }

    const m = Math.floor(time / 1000 / 60);
    const s = Math.floor(time / 1000 - m * 60);

    if (locale === 'ko') return `${m > 0 ? m.toLocaleString('ko-KR') : 0}분 ${s > 0 ? s : 0}초`;
    if (m > 1) return `${m.toLocaleString('ko-KR')}mins ${s}sec`;
    if (m > 0) return `${m}min ${s}sec`;
    return `0min ${s > 0 ? s : 0}sec`;
};

export const dateFormatter = (locale: 'ko' | 'en', dateString: string) => {
    const date = new Date(dateString);

    if (locale === 'en') {
        return `${date.toLocaleString('en', {
            month: 'short',
        })} ${date.getDate()}, ${date.getFullYear()}`;
    }

    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
};

export const periodCalculator = (locale: string, requiredDate?: Date) => {
    const momentDate = moment(requiredDate);
    let date = new Date(momentDate.format());

    const today =
        locale === 'ko'
            ? `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`
            : `${date.toLocaleString('en', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;

    momentDate.add(1, 'month');
    date = new Date(momentDate.format());

    const next =
        locale === 'ko'
            ? `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`
            : `${date.toLocaleString('en', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;

    momentDate.subtract(1, 'day');
    date = new Date(momentDate.format());

    const later =
        locale === 'ko'
            ? `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`
            : `${date.toLocaleString('en', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;

    return { today, later, next };
};

export const timeForNow = (locale: 'ko' | 'en', time: string) => {
    const today = new Date();
    const timeValue = new Date(time);

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
    if (betweenTime < 1) {
        return locale === 'ko' ? '방금' : 'just now';
    }
    if (betweenTime === 1) {
        return locale === 'ko' ? '1분 전' : 'a min ago';
    }
    if (betweenTime < 60) {
        return locale === 'ko' ? `${betweenTime}분 전` : `${betweenTime} mins ago`;
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour === 1) {
        return locale === 'ko' ? '1시간 전' : 'an hour ago';
    }
    if (betweenTimeHour < 24) {
        return locale === 'ko' ? `${betweenTimeHour}시간 전` : `${betweenTimeHour} hours ago`;
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTime === 1) {
        return locale === 'ko' ? '1일 전' : 'a day ago';
    }
    if (betweenTimeDay <= 7) {
        return locale === 'ko' ? `${betweenTimeDay}일 전` : `${betweenTimeDay} days ago`;
    }

    return time.split(' ')[0];
};

export const getDuration = (fileURL: string) =>
    new Promise<number>((resolve: (value: number) => void) => {
        const audio = document.createElement('audio');
        audio.src = fileURL;
        audio.load();

        audio.onloadeddata = async () => {
            audio.remove();
            return resolve(audio.duration * 1000);
        };
    });

export const durationFormatter = (e: number) => {
    const minute = Math.trunc(e / 60);
    const seconds = Math.trunc(e) - 60 * minute;
    return `${minute > 0 ? minute : `0${minute}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
};
