import type { PlanResponse } from '@modules/types/api/plan';
import type { Plan } from '@modules/types/plan';
import type { NewVoiceModelResponse } from '@modules/types/api/voiceModel';
import type { VoiceModel } from '@modules/types/voice';
import type { InquiryResponse } from '@modules/types/api/help';
import type { Inquiry } from '@modules/types/help';

export const planFormatting = (planList: PlanResponse[]): Plan[] => {
    const tmp: Plan[] = [];
    // eslint-disable-next-line no-restricted-syntax
    planList.forEach((item, index) => {
        tmp.push({
            id: index + 1,
            level: item.tier,
            name: item.plan,
            description: item.subtitle,
            price: item.price,
            priceEn: item.price_en,
            availableTime: item.plan_time,
            availableCharacters: item.plan_txt,
            customVoice: item.cv_cnt === -1 ? Infinity : item.cv_cnt,
            features: item.func,
        });
    });
    return tmp;
};

export const voiceModelFormatting = (voiceModelList: NewVoiceModelResponse[], locale: 'ko' | 'en') => {
    return voiceModelList.reduce<VoiceModel[]>((acc, voiceModel) => {
        const isBasic = voiceModel.type === 'BASIC';
        const isEnglishSpeaker = voiceModel.uuid.includes('en');

        const shouldAdd =
            (locale === 'ko' && !(isBasic && isEnglishSpeaker)) || (locale === 'en' && !(isBasic && !isEnglishSpeaker));

        if (shouldAdd) {
            acc.push(voiceModel);
        }

        return acc;
    }, []);
};

export const inquiryListFormatting = (data: InquiryResponse[]): Inquiry[] => {
    const tmp: Inquiry[] = [];

    for (let i = 0; i < data.length; i++) {
        const inquiry = data[i];
        const questionImageList: Inquiry['question']['imageList'] = [];
        const answerImageList: Inquiry['answer']['imageList'] = [];

        if (inquiry.inquiry_img.length > 0) {
            for (let j = 0; j < inquiry.inquiry_img.length; j++) {
                questionImageList.push({
                    fileName: inquiry.inquiry_img[j].originalname,
                    URL: inquiry.inquiry_img[j].s3_img_signd,
                });
            }
        }
        if (inquiry.answer.answer_img.length > 0) {
            for (let j = 0; j < inquiry.answer.answer_img.length; j++) {
                answerImageList.push({
                    fileName: inquiry.answer.answer_img[j].originalname,
                    URL: inquiry.answer.answer_img[j].s3_img_signd,
                });
            }
        }

        tmp.push({
            id: inquiry.no,
            state: inquiry.answer.state === '0' ? 'received' : inquiry.answer.state === '1' ? 'waiting' : 'completed',
            category: inquiry.category,
            question: {
                content: inquiry.content,
                imageList: questionImageList,
                date: inquiry.createdAt.split(' ')[0].replaceAll('-', '.'),
            },
            answer: {
                content: inquiry.answer.content,
                imageList: answerImageList,
                date: inquiry.answer.createdAt.split(' ')[0].replaceAll('-', '.'),
            },
            isRead: inquiry.read_yn === 'Y',
        });
    }

    return tmp;
};
